import React, { useEffect, useState } from 'react';
import '../../styles/components/SocialLink.css'

const SocialLink = ({title, url, image, initialHeight, finalHeight }) => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const [startGrowing, setStartGrowing] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrollHeight(window.scrollY);

            // Check if the user is almost at the bottom
            const isAlmostAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - initialHeight;

            if (isAlmostAtBottom) {
                setStartGrowing(true);
                // Perform actions or trigger events when almost at the bottom
            } else {
                setStartGrowing(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [initialHeight]);

    const calculateImageHeight = () => {
        const growthFactor = (scrollHeight / (document.body.offsetHeight - window.innerHeight)) || 0;
        return initialHeight + growthFactor * (finalHeight - initialHeight);
    };

    return (
        <img
            className = "socialLink"
            style = {{
                height: `${startGrowing ? calculateImageHeight() : initialHeight}px`,
            }}
            src = {image}
            alt = {title}
            key={title}
            onClick = {() => window.open(url, "_blank")}
        />
    );
};

export default SocialLink;