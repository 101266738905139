// Services.js

import React from 'react';
import "../styles/App.css";

const Services = () => {
    return (
        <div className="information-container">
            <h2>Services</h2>
            <div>
                <h3>Quality</h3>
                <p>+ Finding the biggest risk and mitigate it.</p>
                <p>+ Identifying error prone areas.</p>
            </div>
            <div>
                <h3>Process</h3>
                <p>+ Look for missing communication lines</p>
                <p>+ Strategies that works for the company and teams.</p>
            </div>
            <div>
                <h3>Culture</h3>
                <p>+ Shaping teams.</p>
                <p>+ Set up tools.</p>
            </div>


        </div>
    );
}

export default Services;
