import SocialLink from "./components/SocialLink";
import "../styles/pages/Footer.css"

const Footer = ({socials}) => {
    const calcFinalHeight = () => {
        //if the window.innerWidth leaves enough space for 4 it should be 150 otherwise it should be capped.
        //accounting for padding and all the rest of it
        let size = (window.innerWidth-110)/4;
        return size>150?150:size;
    }

    return (
        <div className="ScrollDetectionFooter" id="socials">
            {socials.map((social) => {
                return <SocialLink
                    initialHeight = {20}
                    finalHeight = {calcFinalHeight()}
                    image = {social.image}
                    url = {social.url}
                    title = {social.name}
                    key={social.name}
                />
            })}
        </div>
    );
};

export default Footer;