import '../styles/pages/Experiences.css';
import GalleryCard from "./components/GalleryCard";

import {useEffect, useState} from "react"; // Import your CSS file for styling

const Experiences = ({ experiences }) => {
    const [offsetWidth, setOffsetWidth] = useState((window.innerWidth - 600) / 2);

    useEffect(() => {
        const handleResize = () => {
            // Update width based on the calculation
            setOffsetWidth((window.innerWidth - 600) / 2);
        };

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const calculateOffsetWidth = () => {
        return { width: `${offsetWidth}px`,
            height: "1px"};
    };

    return (
        <div>
            <h2>Experiences</h2>
            <div className={`ImageGallery`}>
            <div className="offset" style={calculateOffsetWidth()}></div>
            {experiences.map((experience) => {
                return <GalleryCard
                    key={experience.name}
                    image = {experience.image}
                    description = {experience.description}
                    keyTools = {experience.keyTools}
                />
            })}
            <div className="offset" style={calculateOffsetWidth()}></div>
            </div>
        </div>
    );
};

export default Experiences;
