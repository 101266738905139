import twitterLogo from "./twitter_logo.png";
import linkedinLogo from "./linkedin_logo.png";
import wordpressLogo from "./wordpress_logo.png";
import emailLogo from "./email_logo.png";

export const socials = [
    {
        image: twitterLogo,
        url: "https://twitter.com/vdlgeert",
        name: "twitter"
    },
    {
        image: linkedinLogo,
        url: "https://www.linkedin.com/in/vdlgeert/",
        name: "linkedin"
    },
    {
        image: wordpressLogo,
        url: "https://testheadr.wordpress.com",
        name: "wordpress"
    },
    {
        image: emailLogo,
        url: "mailto:vdlgeert@gmail.com",
        name: "mail"
    }
]