import Bayer from "./Bayer.png";
import Objectway from "./Objectway.png";
import Mediahuis from "./Mediahuis.png";
import Scoutbee from "./Scoutbee.png";
import Adhese from "./Adhese.png";

export const experiences = [
    {
        name: "Bayer",
        image:{src: Bayer, alt: 'BayerLogo'},
        description:
            "Clever use of toolboxData helped us create toolboxData for crop analysis in the field and in the lab." +
            "Performance toolboxData and good feedback helped us create the perfect stack for these scientists.",
        keyTools:["Dynatrace", "Manual testing", "Neoload"]
    },
    {
        name: "Objectway",
        image:{src: Objectway, alt: 'Objectway'},
        description:
            "Throught the use of test sessions we were able to provide clear feedback on recent developments.\n"+
            "By setting up integration testing with Postman we ensured repeateable and reliable test results.",
        keyTools:["Postman", "Manual Testing"]
    },
    {
        name: "Mediahuis",
        image:{src: Mediahuis, alt: 'MediahuisLogo'},
        description: "The test strategy here was able to provide us a full 360 view on the application. \n"+
        "Automated testing through Postman, Cypress, Selenium and other toolboxData gave us constant feedback. \n"+
        "By working as a team we could confidently release and grow our application.",

        keyTools:["Postman", "Cypress", "Selenium", "Shift Left"]
    },
    {
        name: "Scoutbee",
        image:{src: Scoutbee, alt: 'Scoutbee'},
        description: "Improving quality, not through testers, but through better processes. \n" +
            "A quality mindset and good communication helped their teams to reach their goals.",
        keyTools:["Bugbashing", "Quality Culture"]
    },
    {
        name: "Adhese",
        image:{src: Adhese, alt: 'Adhese'},
        description: "Bringing structure and a quality mindset to this company and stabilize their stack." +
            "The right processes, the right toolboxData and the right communication will bring us out on top!",
        keyTools:["Quality Culture", "Test Automation", "Risk Based Testing"]
    },
];