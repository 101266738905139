import React, { useState } from 'react';
import '../../styles/components/InputLabel.css'; // Make sure to create a corresponding CSS file


const LabeledInputField = ({ label, formHandleChange, type, submitSuccessful, multiline }) => {
    const [focused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        if (inputValue.trim() === '') {
            setFocused(false);
        }
    };

    const handleChange = (e) => {
        setInputValue(e.target.value);
        return formHandleChange(e);
    };

    const InputType = multiline ? 'textarea' : 'input';

    return (
        <div className={`input-container ${focused || inputValue ? 'focused' : ''}`}>
            <label htmlFor="inputField">{label}</label>
            <InputType
                name={label}
                type={type|| 'text'}
                id="inputField"
                value={inputValue}
                disabled = {submitSuccessful}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                className={multiline ? 'multiline' : ''}
            />
        </div>
    );
};

export default LabeledInputField;