// Header.js
import React, {useEffect, useState} from 'react';
import '../styles/pages/Header.css';

const Header = ({navigationElements}) => {
    const showMobileViewWidth = 1000;
    const [showNav, setShowNav] = useState(false);
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > showMobileViewWidth);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > showMobileViewWidth);
            setShowNav(false);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLinkClick = (id) => {
        const element = document.getElementById(id);
        const yOffset = 68;
        const y = element.getBoundingClientRect().top + window.pageYOffset + -yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
        toggleNav();
    };

    const toggleNav = () => {
        setShowNav(!showNav);
    };

    const generateSections = () => {
        return navigationElements.flatMap((navElement) => {
            return [<span key={navElement} onClick={() => handleLinkClick(navElement)}>{navElement}</span>]
        });
    }

    return (
        <header className="widescreen-menu">
            {isWideScreen ? (
                <nav>
                    {generateSections()}
                </nav>
            ) : (
                <nav className="hamburger-menu">
                    <span className={showNav ? 'close-icon' : ''} onClick={toggleNav}>
                        {showNav ? '✖' : '☰'}
                    </span>
                    {showNav && generateSections()}
                </nav>
            )}
        </header>
    );
};

export default Header;
