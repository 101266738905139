// App.js
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import Toolbox from './components/Toolbox';
import Contact from './components/Contact';
import Experiences from './components/Experiences';
import Footer from './components/Footer';

import {navigationElements} from "./resources/navigationElements";
import {slogans} from './resources/slogans'
import {toolboxData} from './resources/Toolbox/toolboxData'
import {experiences} from './resources/Experiences/experiencesData'
import {socials} from './resources/Socials/socialsData';

import './styles/App.css';

function App() {



    return (
        <div>
            <Header navigationElements={navigationElements} />

            <div className="component-container">
                <section id="vdlgeert" className="light-background">
                    <Home slogans={slogans}/>
                </section>
                <section id="services" className="dark-background">
                    <Services />
                </section>
                <section id="toolbox" className="light-background">
                    <Toolbox tools={toolboxData}/>
                </section>
                <section id="experiences" className="dark-background">
                    <Experiences experiences={experiences}/>
                </section>
                <section id="contact" className="light-background">
                    <Contact/>
                </section>
                <section  className="light-background">
                    <div className="scrolldetectorOffset"/>
                </section>
            </div>

            <Footer socials={socials} />
        </div>
    );
}

export default App;
