import postmanLogo from "./postman.png"
import cypressLogo from "./cypress.png"
import dynatraceLogo from "./dynatrace.png"
import neoloadLogo from "./neoload.png"
import seleniumLogo from "./selenium.png"
import shiftLeftLogo from "./shiftLeft.png"
import bugBashingLogo from "./bugBashing.png"
import cultureLogo from "./culture.png"




export const toolboxData = [
    {
        name: "Quality Culture",
        description:
            "Fostering a quality culture is at the core of our approach, where collaboration, continuous improvement, " +
            "and a shared commitment to excellence define our workplace. " +
            "We prioritize maintaining high standards of product quality, ensuring that every team member, " +
            "from developers to managers, actively contributes to the pursuit of excellence.",
        logo: cultureLogo
    },
    {
        name: "Postman",
        description:
            "Utilizing tools like Postman, we streamline API development by offering " +
            "a collaborative platform for testing, and managing APIs. " +
            "With features that simplify API requests, testing, and performance monitoring, " +
            "Postman empowers us to ensure the robustness and reliability of the APIs " +
            "that drive our customers' applications.",
        logo: postmanLogo
    },
    {
        name: "Shift Left",
        description:
            "Embracing the \"Shift Left\" methodology, we prioritize addressing and preventing issues " +
            "early in the development process, from design to coding phases. " +
            "By proactively identifying and rectifying potential issues at an earlier stage, " +
            "we significantly reduce the overall cost and time associated with fixing issues, " +
            "ensuring a smoother development and testing process.",
        logo: shiftLeftLogo
    },
    {
        name: "Cypress",
        description:
            "Cypress, a JavaScript end-to-end testing framework, plays a pivotal role in our testing strategy " +
            "by allowing us to write and run tests directly in the browser. " +
            "This approach provides fast and reliable feedback on the functionality of web applications, " +
            "enhancing the efficiency of our testing processes.",
        logo: cypressLogo
    },
    {
        name: "BugBashing",
        description:
            "Our bugbashing sessions are interactive and collaborative testing activities " +
            "that bring together developers, testers, and stakeholders to swiftly identify bugs in software applications. " +
            "This time-boxed approach allows us to efficiently improve overall software quality, " +
            "fostering a proactive stance towards bug detection and resolution.",
        logo: bugBashingLogo
    },
    {
        name: "Selenium",
        description:
            "Selenium, our go-to open-source framework for automating web browsers, " +
            "enables us to script interactions with web applications. " +
            "This automation ensures the functionality and stability of software, " +
            "allowing us to deliver high-quality products that meet and exceed customer expectations.",
        logo: seleniumLogo
    },
    {
        name: "Dynatrace",
        description:
            "For real-time insights into application performance and health, we turn to Dynatrace. " +
            "As an application performance monitoring (APM) tool, Dynatrace helps us optimize user experience, " +
            "identify and resolve performance issues, and ensure the overall reliability of our customers' applications.",
        logo: dynatraceLogo
    },
    {
        name: "Neoload",
        description:
            "In performance testing, we rely on Neoload to simulate virtual users and " +
            "analyze applications under various conditions. This tool enables us to identify " +
            "and address bottlenecks and weaknesses in application architecture, " +
            "ensuring optimal performance and a seamless user experience.",
        logo: neoloadLogo
    }
]