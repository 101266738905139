import "../../styles/components/GalleryCard.css";


const GalleryCard = ({image, description, keyTools}) => {

    const minimumDescriptionWidth = 750

    const rowOrColumn = () => {
        return {flexDirection: window.innerWidth-110 <= minimumDescriptionWidth? 'column' : 'row'}
    }


    return (<div className="GalleryCard" >
        <div className="cardContent" style={rowOrColumn()}>
            <img src={image.src}  alt={image.alt}/>
            <div className="description">{description}</div>
        </div>
        <div className="toolBox">
            {keyTools.map((keyTool) => {
                return <div key={keyTool} className="tool" >{keyTool}</div>
            })}
        </div>
    </div>)
}
export default GalleryCard;