// Toolbox.jsx
import React, { useState, useEffect } from 'react';
import '../styles/pages/Toolbox.css'; // Import your CSS file

const Toolbox = ({tools}) => {
    const [selectedTool, setSelectedTool] = useState(tools[0]);
    const [offsetWidth, setOffsetWidth] = useState((window.innerWidth - 780) / 2);

    useEffect(() => {
        const handleResize = () => {
            // Update width based on the calculation
            setOffsetWidth((window.innerWidth - 600) / 2);
        };

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [offsetWidth]);

    const calculateOffsetWidth = () => {
        return { width: `${offsetWidth}px`,
            height: "1px"};
    };

    const handleToolClick = (tool) => {
        setSelectedTool(tool);
    };

    return (
        <div>
            <h2>My toolbox</h2>
            <div>
                <ul className="toolList">
                    <li className="offset" style={calculateOffsetWidth()}/>
                    {tools.map((tool) => (
                        <li className="toolLabel" key={tool.name} onClick={() => handleToolClick(tool)}>
                            {tool.name}
                        </li>
                    ))}
                    <li className="offset" style={calculateOffsetWidth()}/>
                </ul>
                <div className="information-container">
                    <div>
                        {selectedTool && (
                            <div className="toolCard">
                                <h2>{selectedTool.name}</h2>
                                <div className="toolContent">
                                    <img className="toolLogo" src={selectedTool.logo} alt={`${selectedTool.name} logo`} />
                                    <p className="toolDesc">{selectedTool.description}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Toolbox;
