// Contact.js

import "../styles/App.css";
import "../styles/pages/Contact.css"
import LabeledInputField from "./components/LabeledInputField";
import {useState} from "react";
const Contact = () => {

    const initialFormFieldValues = {
        name: "",
        email: "",
        message: ""
    }

    const [formFieldValues, setFormFieldValues] = useState(initialFormFieldValues);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const encode = (data: {}) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contactform", ...formFieldValues}),
        })
            .then(setSubmitSuccess(true))
            .catch(error => alert(error));

        e.preventDefault();
    };

    const handleChange = (input) => {
        const key = input.target.name;
        const value = input.target.value;
        setFormFieldValues((prevValues) => ({
            ...prevValues,
            [key]: value,
        }));
    };

    return (
        <div className="information-container" >
            <h2>Get in contact</h2>
            <form className="contactform"
                  data-netlify="true"
                  name="contactform"
                  method="post"
                  onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contactform" onChange={handleChange} />
                <LabeledInputField label={"name"} formHandleChange={handleChange} submitSuccessful={submitSuccess}/>
                <LabeledInputField type="email" label={"email"} formHandleChange={handleChange} submitSuccessful={submitSuccess}/>
                <LabeledInputField type="textarea" label={"message"} multiline={true}  formHandleChange={handleChange} submitSuccessful={submitSuccess}/>
                <button type="submit">GET IN CONTACT</button>
            </form>
        </div>
);
}

export default Contact;
