import "../../styles/components/Slogan.css";
import React, { useState, useEffect } from 'react';

const Slogans = ({ slogans }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeout(() => {
                setActiveIndex((prevIndex) => (prevIndex + 1) % slogans.length);
            }, 1000); // Wait for 1 second after changing the active index
        }, 4000); // Interval of 4000 milliseconds (4 seconds) for each slogan
        return () => clearInterval(intervalId);
    }, [slogans]);

    return (
        <div className="sloganContainer">
            {slogans.map((slogan, index) => (
                <p key={index} className={`slogan ${index === activeIndex ? 'active' : ''}`}>
                    {slogan}
                </p>
            ))}
        </div>
    );
};

export default Slogans;