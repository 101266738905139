//packages
import React, {useEffect, useState} from 'react';

//styles
import "../styles/pages/Home.css";

//resources
import profileImage from '../resources/Thomas.jpg';
import Slogans from "./components/Slogans";

const Home = ({slogans}) => {
    const desiredScreenWidth = 1240;
    const appointmentPlanner = "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0-MQyKIqFQItDg79gaWJJRLmwSe_uchrgWX3hYVxzvEPaBBazAFBsxDLKewafailZp_IKpMG2l"
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > desiredScreenWidth);


    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > desiredScreenWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

const openPlanner = () => {
    window.open(appointmentPlanner, "_blank")
}

    return (
        <div className={`content-container ${isWideScreen ? 'flex-row' : 'flex-column'}`}>
            <div className="image-container">
                <img src={profileImage} alt="Profile" className="rounded-image" />
            </div>

            <div className="information-container" >
                <h1>vdlgeert</h1>
                <Slogans slogans={slogans} />
                <div className="ctaContainer">
                    <div className="ctaButton"
                         onClick={openPlanner}>Let's chat!</div>
                </div>

            </div>

            <div className="offset-container">
                &nbsp;
            </div>
        </div>
    );
}

export default Home;